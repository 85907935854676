table {
  font-size: 13px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54) !important;
}

.dx-pages {
  float: none !important;
  text-align: center !important;
}

.dx-page {
  background: #fff !important;
  color: #337ab7 !important;
  border: 1px solid #ddd !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-radius: 0 !important;
}

.dx-page.dx-selection {
  color: #fff !important;
  background: #337ab7 !important;
  border-color: #337ab7 !important;
}

.dx-navigate-button.dx-prev-button,
.dx-navigate-button.dx-next-button {
  color: #337ab7;
  border: 1px solid #ddd;
  padding: 7px 13px !important;
}

.dx-navigate-button.dx-button-disable {
  color: #ddd !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row {
  font-weight: bold;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row td {
  vertical-align: middle;
}
.dx-data-row {
  height: 55px;
  position: relative;

  &.is-copied td {
    background-color: #83ce78;
  }
}
.dx-data-row td {
  vertical-align: middle !important;
  transition: background-color .8s;
}
.dx-datagrid .dx-column-lines > td {
  border-left: 0;
  border-right: 0;
}

.dx-widget.dx-datagrid-pager.dx-pager {
  position: absolute;
  bottom: -60px;
  border-top: 0;
}

.dx-pager .dx-pages .dx-page {
  padding: 7px 12px 8px;
}

.dx-datagrid-nowrap, .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
  white-space: normal;
  text-align: center;
}
.dx-datagrid .dx-row > td.padding-left-1 {
  padding-left: 1px;
}

.dx-datagrid-focus-overlay {
  display: none
}
