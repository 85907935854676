tr.dx-row.dx-column-lines.dx-header-row {
  background: #4a89dc;
  color: white;
}

.dx-pager .dx-pages .dx-navigate-button {
  height: 18px !important;
  background-color: white;

  &.dx-next-button {
    border-left: 0;
  }

  &.dx-prev-button {
    border-right: 0;
  }
}

.MuiDialogContent-root .MuiFormHelperText-root {
  position: absolute;
  top: 100%;
  color: red;
  line-height: 1;
}
.MuiFormControl-root .MuiFormLabel-root.labelReport {
  left: -15px;
}

.dx-loadpanel-wrapper {
  position: fixed;
  background-color: rgba(255, 255, 255, .5);
}
.dx-overlay-shader {
  background-color: transparent;
}
