.SimpleDataGrid {
  &-container {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 10px 0;
  }

  &-pagination {
    .Mui-selected {
      background: #4a89dc !important;
      color: var(--ol-background-color) !important;
    }
  }
}
