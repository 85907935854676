.signs-schedule-table {
  .tableDefaultField-days {
    padding: 0 !important;
    vertical-align: top !important;

    table {
      width: 100%;
    }

    thead {
      tr {
        background-color: #5a5a5a !important;

        th {
          height: 35px;
          padding: 0.65rem 2.5px;

          &.is-no-active {
            color: #7a6d61;
          }
        }

        th:nth-last-child(-n + 2) {
          &.is-active {
            color: red;
          }
        }

        th:nth-child(-n + 5) {
          &.is-active {
            color: #fff;
          }
        }
      }
    }

    tbody {
      td {
        height: 35px;
      }
    }
  }
}
