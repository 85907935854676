.signs-schedule-table {
  &.tableDefault:not(.isDialog) .tableDefaultField:nth-child(1) {
    text-align: center;
    padding: 0 0.5rem;
  }

  .tableDefaultHead-flashing th {
    background-color: #538ed5;
    color: #fff;
    height: 55px;
    font-size: 0.75rem;
    padding: 0;
    text-align: center;
    border: none;

    &.actions {
      display: table-cell;
    }

    &.dots {
      display: table-cell;
    }

    @media (min-width: 1475px) {
      &.dots {
        display: none;
      }
    }
  }
  .tableDefaultHead-flashing td {
    padding: 0;
  }

  .tableDefaultField-flashing {
    padding: 0;
    border: none;
  }

  .tableDefaultField-flashing2 {
    min-width: 75px;
    height: 55px;
    background-color: #fcd5b4;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    text-align: center;
    border: none;
    color: var(--color-text-modal);

    &-button {
      padding: 0;
    }

    &::after {
      content: none !important;
    }

    &.tableDefaultField-action {
      display: none;

      button {
        min-width: auto;
      }
    }

    &.tableDefaultField-dots {
      display: table-cell;

      button {
        padding: 0;
      }
    }

    @media (min-width: 1475px) {
      &.tableDefaultField-action {
        display: table-cell;
      }

      &.tableDefaultField-dots {
        display: none;
      }
    }
  }
}
