.reportTable {
  &-header {
    display: flex;
    &-content {
      flex: 1 1 100%;
      display: flex;
      justify-content: space-around;
      color: #757575;
      line-height: 22px;
      margin-bottom: 10px;

      &-left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    h6 {
      color: #000;
    }
  }

  .reportTable-exportCSV {
    margin-bottom: 10px;
  }
}
