@import './_days.scss';
@import './_ds.scss';
@import './_flashing.scss';

.signs-schedule-table {
  color: var(--color-text-primary);

  .tableDefaultHead-fat {
    background-color: #e08214;
    color: #000;
  }

  &.tableDefaultTable {
    table-layout: fixed;
    margin-bottom: 0;
    border-right: 1px solid;
    border-bottom: 1px solid;
  }

  .tableDefaultField {
    &:after {
      content: none !important;
    }
  }

  .tableDefaultHeader {
    & th:nth-child(1) {
      width: 5%;
    }

    & th:nth-child(2) {
      width: 35%;
    }

    & th:nth-child(3) {
      width: 35%;
    }

    & th:nth-child(4) {
      width: 25%;
    }
  }

  .tableDefaultBody {

    .tableDefaultField- {
      width: 5%;

      &days {
        width: 35%;
      }

      &ds {
        width: 35%;
      }

      &flashing {
        width: 25%;
      }
    }
  }
}